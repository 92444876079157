import React from 'react';
//import { withTranslation } from 'react-i18next';

class Footer extends React.Component {
    // constructor(props) {
    //   super(props);
    // }
    render() {
      //const { t } = this.props;
      return (
       <>
        <footer className="dark_footer cps_footer">
          <div className="copy_section">
            <p>Copyright © 2023 - 2024 Caesium | All Rights Reserved.</p>
          </div>
        </footer>
       </>
      );
    }
  }

  export default Footer;
  //export default withTranslation()(Footer);