import React from 'react';
import Frame from "../../components/frame";

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  render() {
    //const { t } = this.props;

    return (
      <Frame withHeader={true} withFooter={true}>
        <section className="cps_banner_bg">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6 col-lg-6">
                <div className="cps_banner_content">
                  <h1>Become a Part of Liquidity Provider</h1>
                  <h4>Your Gateway to Financial Success!</h4>
                  <p>Welcome to the Caesium Profit Sharing (CPS) Club, where opportunities for lucrative rewards await.</p>
                  <div className="mt-5">
                    <a className="header_btn" href="/cps">Join Our Club</a>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-6">
                <div className="banner_image">
                  <img src="assets/img/cps-banner.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="pt-5 pb-5">
          <div className="container">
            <div className="cps_heading_text heading_middle mb-5">
              <h2 className="mb-3">Get Share up to $1,100,000</h2>
              <h5 className="mb-0">Complete Level 12 </h5>
            </div>
            <div className="row g-5">
              <div className="col-md-6 col-lg-6">
                <div className="Profit_Sharing_box text-center">
                  <img src="assets/img/profit.png" alt="" />
                  <h2>Profit Sharing <span>Mastery:</span> </h2>
                  <p>Join a program designed for maximum profit sharing, with up to $1.1 million in potential earnings across 12 cycles.</p>
                </div>
              </div>
              <div className="col-md-6 col-lg-6">
                <div className="Profit_Sharing_box text-center">
                  <img src="assets/img/smart-contract.png" alt="" />
                  <h2>Smart Contract <span> Assurance:</span> </h2>
                  <p>Trust in our transparent smart contract, providing a secure investment environment. </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-6">
                <div className="Profit_Sharing_box text-center">
                  <img src="assets/img/flexible.png" alt="" />
                  <h2>Flexible <span>Withdrawals:</span> </h2>
                  <p>No lock-in period – claim your earnings at your convenience.</p>
                </div>
              </div>
              <div className="col-md-6 col-lg-6">
                <div className="Profit_Sharing_box text-center">
                  <img src="assets/img/global-profits.png" alt="" />
                  <h2>Global <span>Profits:</span> </h2>
                  <p>Benefit from the majority of global profits shared from Cycle 2 to 12.</p>
                </div>
              </div>
              <div className="col-md-12 col-lg-12">
                <div className="Profit_Sharing_box extra_padding">
                  <div className="row align-items-center">
                    <div className="col-md-4 col-lg-4">
                      <img src="assets/img/exclusive-membership.png" alt="" />
                    </div>
                    <div className="col-md-8 col-lg-8">
                      <h2 className="mb-3">Exclusive Membership</h2>
                      <p>Gain access to a range of benefits, including a $200 BNB worth, educational resources, and first access to new offerings.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="pt-5 pb-5">
          <div class="container">
              <div class="cps_heading_text heading_middle mb-5">
                  <h2 class="mb-3">Why join the CPS club?</h2>
              </div>
              <div class="join_cps_club_box">
                  <div class="row g-0">
                      <div class="col-md-4 col-lg-4">
                          <div class="left_sticky">
                              <img class="cps_man_img" src="assets/img/cps-man.png" alt="" />
                          </div>
                      </div>
                      <div class="col-md-8 col-lg-8">
                          <div class="sticky_text_box">
                              <div class="sticky_text">
                                  <h4>Smart Contract Transparency</h4>
                                  <p>Our CPS Club operates on a secure and transparent
                                      smart contract, ensuring a trustworthy investment
                                      environment.</p>
                              </div>
                              <div class="sticky_text">
                                  <h4>Auto Placement Platform</h4>
                                  <p>Introduce 3 IDs in Cycle 1 to qualify for auto placement,
                                      maximizing your potential earnings.</p>
                              </div>
                              <div class="sticky_text">
                                  <h4>No Lock-in Period</h4>
                                  <p>Enjoy the flexibility of claiming your earnings without
                                      any lock-in period. Immediate withdrawals available.</p>
                              </div>
                              <div class="sticky_text">
                                  <h4>Global Profit Sharing: </h4>
                                  <p>Benefit from the majority of global profits shared from
                                      Cycle 2 to 12, enhancing your income potential.</p>
                              </div>
                              <div class="sticky_text">
                                  <h4>Membership Benefits: </h4>
                                  <p>As a member, you gain exclusive access to a range of
                                      benefits, including a $200 BNB worth, educational
                                      resources, and first access to new offerings.</p>
                              </div>
                          </div>
                        
                      </div>
                  </div>
              </div>
          </div>
      </section>

        <section className="pt-5 pb-5">
          <div className="container">
            <div className="transparent_operations_box">
              <div className="transparent_content">
                <h2>Transparent Operations:</h2>
                <p>Our smart contract ensures transparency, providing a trustworthy investment platform.</p>
              </div>
              <img className="tranparent_img moveVertical" src="assets/img/tranparent.png" alt="" />
            </div>
          </div>
        </section>

        <section className="pt-5 pb-5 mechanism_bg">
          <div className="container">
            <div className="row g-4 align-items-center">
              <div className="col-md-6 col-lg-6">
                <div className="cps_step_box">
                  <h2>1</h2>
                  <div className="cps_step_box_content">
                    <h3><span>Join</span> the CPS Club:</h3>
                    <p>Start your journey towards profitable cycles and financial growth.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-6">
                <div className="cps_step_box_img left_padding">
                  <img src="assets/img/business-life.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="pt-5 pb-5">
          <div className="container">
            <div className="row g-4 align-items-center">
              <div className="col-md-6 col-lg-6">
                <div className="cps_step_box_img right_padding">
                  <img src="assets/img/join.png" alt="" />
                </div>
              </div>
              <div className="col-md-6 col-lg-6">
                <div className="cps_step_box">
                  <h2>2</h2>
                  <div className="cps_step_box_content">
                    <h3><span>Advance</span> through Levels</h3>
                    <p>Progress by introducing new members and expanding your network.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="auto-Placement position-relative">
          <div className="container">
            <div className="row g-4 align-items-center">
              <div className="col-md-6 col-lg-7">
                <div className="Auto_Placement_text">
                  <h3>Auto Placement</h3>
                  <p>Qualify for auto placement by introducing 3 IDs in Cycle 1, unlocking even more earning potential.</p>
                </div>
              </div>
              <div className="col-md-6 col-lg-5">
                <h2 className="Auto_Placement_text_3">3</h2>
              </div>
            </div>
          </div>
        </section>

        <section className="pt-5 pb-5">
          <div className="container">
            <div className="row g-4 align-items-center">
              <div className="col-md-6 col-lg-6">
                <div className="cps_step_box">
                  <h2>4</h2>
                  <div className="cps_step_box_content">
                    <h3><span>Claim</span> Your Rewards</h3>
                    <p>Start your journey towards profitable cycles and financial growth.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-6">
                <div className="subscription_img left_padding mb-4">
                  <img src="assets/img/subscription.png" alt="" />
                </div>
                <h4 className="text-center mb-0">Subscription Successful</h4>
              </div>
            </div>
            <h6 className="text-center dont_miss_text mt-5 mb-0">Don't miss out on this incredible opportunity! Join the Caesium Profit Sharing Club today and embark on a journey of financial growth and success.</h6>
          </div>
        </section>

        <section class="pt-2 pb-4">
          <div className="container">
            <div className="youtube-container">
              <iframe src="https://www.youtube.com/embed/qpJzaXtOrfQ" title="Caesium Profit Sharing Club (CPS)" allowfullscreen></iframe>
            </div>
          </div>
        </section>

        <section className="pt-3 pb-5 position-relative">
          <div className="container">
            <div className="Cycle-based_bg mb-5">
              <div className="cps_heading_text heading_middle mb-5">
                <h2 className="mb-3">Cycle-based Profit Sharing (USD):</h2>
                <h5 className="mb-0">User have to Request the data to show the below content - Verify </h5>
              </div>
              <div className="table_box Cycle-based_table">
                <table className="table table-border table-striped">
                  <thead>
                    <tr>
                      <td>Cycle</td>
                      <td>Profit Sharing (USD)</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Level 1</td>
                      <td>$100</td>
                    </tr>
                    <tr>
                      <td>Level 2</td>
                      <td>$120</td>
                    </tr>
                    <tr>
                      <td>Level 3</td>
                      <td>$200</td>
                    </tr>
                    <tr>
                      <td>Level 4</td>
                      <td>$350</td>
                    </tr>
                    <tr>
                      <td>Level 5</td>
                      <td>$610</td>
                    </tr>
                    <tr>
                      <td>Level 6</td>
                      <td>$1,140</td>
                    </tr>
                    <tr>
                      <td>Level 7</td>
                      <td>$2,280</td>
                    </tr>
                    <tr>
                      <td>Level 8</td>
                      <td>$6,840</td>
                    </tr>
                    <tr>
                      <td>Level 9</td>
                      <td>$19,000</td>
                    </tr>
                    <tr>
                      <td>Level 10</td>
                      <td>$85,600</td>
                    </tr>
                    <tr>
                      <td>Level 11</td>
                      <td>$371,800</td>
                    </tr>
                    <tr>
                      <td>Level 12</td>
                      <td>$612,000</td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td>Total</td>
                      <td>$1,100,000</td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="cps_heading_text heading_middle mb-5">
              <h2 className="mb-0">CPS Club Liquidity Distribution:</h2>
            </div>
            <div className="row g-4">
              <div className="col-md-4 col-lg-4">
                <div className="Rewars_user_box">
                  <img src="assets/img/rewards.png" />
                  <h2>Rewards to <br /> users </h2>
                  <p>50% of Membership Collection</p>
                </div>
              </div>
              <div className="col-md-4 col-lg-4">
                <div className="Rewars_user_box">
                  <img src="assets/img/liquidity.png" />
                  <h2>Liquidity Pool for CSM Token (BNB) </h2>
                  <p>50% of Membership Collection</p>
                </div>
              </div>
              <div className="col-md-4 col-lg-4">
                <div className="Rewars_user_box">
                  <img src="assets/img/trading.png" />
                  <h2>Trading <br /> Availability </h2>
                  <p>Once 100,000 Members Reached - CSM/BNB on Decentralized Exchanges (DEX) like Uniswap</p>
                </div>
              </div>
            </div>
          </div>
          <img className="Profit-Sharing-shadow" src="assets/img/circle-shadow.png" alt="" />
        </section>

        <section className="pt-5 pb-5 mechanism_bg">
          <div className="container">
            <div className="cps_heading_text mb-5">
              <h2 className="mb-3">Mechanism:</h2>
            </div>
            <div className="row g-2 align-items-center">
              <div className="col-md-5 col-lg-5">
                <div className="mechanism_l_heading">
                  <h2>User <br /> Investment</h2>
                </div>
              </div>
              <div className="col-md-7 col-lg-7">
                <div className="mechanism_r_list">
                  <ul>
                    <li>User invests <span>$200 (worth of BNB).</span></li>
                    <li>CSM mints from the CSM contract, preparing for the DEX pool.</li>
                    <li>50% of investment allocated for CPS qualifier rewards.</li>
                    <li>Another 50% of BNB allocated for DEX Liquidity Pool.</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="row g-2 align-items-center">
              <div className="col-md-5 col-lg-5">
                <div className="mechanism_l_heading">
                  <h2>Claiming <br /> Rewards</h2>
                </div>
              </div>
              <div className="col-md-7 col-lg-7">
                <div className="mechanism_r_list">
                  <ul>
                    <li>CPS qualifiers claim rewards in BNB.</li>
                    <li>Equivalent worth of CSM tokens burns from the DEX pool.</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="row g-2 align-items-center">
              <div className="col-md-5 col-lg-5">
                <div className="mechanism_l_heading">
                  <h2>Estimates</h2>
                </div>
              </div>
              <div className="col-md-7 col-lg-7">
                <div className="mechanism_r_list">
                  <ul>
                    <li>Potential growth of <span>CSM Price in DEX: USD $10 - $50</span></li>
                    <li>Estimate Liquidity Start: <span>$200 * 100,000 * 50% = $10,000,000.</span></li>
                    <li>Estimate CSM in Circulation: <span>1,000,000</span></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

      </Frame>
    );
  }
}

function HomeHOC(props) {
  
  return <Home {...props} />;
}

export default HomeHOC;