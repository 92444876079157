import React from 'react';

class Header extends React.Component {
    // constructor(props) {
    //   super(props);
    // }
    render() {
      return (
        <header className="site-header">
          <div className="navbar">
            <div className="container">
              <div className="brand-logo">
                <a href="/">
                  <img className="dark_logo" src="assets/img/dark-logo.png" alt="" />
                </a>
              </div>
              <div className="menu-block-wrapper">
                <div className="menu-overlay"></div>
                <nav className="menu-block" id="append-menu-header">
                  <div className="mobile-menu-head">
                    <div className="go-back">
                      <i className="fa fa-angle-left"></i>
                    </div>
                    <div className="current-menu-title">
                      <img className="mobile_menu_logo" src="assets/img/dark-logo.png" alt="" />
                    </div>
                    <div className="mobile-menu-close">&times;</div>
                  </div>
                  <ul className="site-menu-main">
                    <li className="fugu-responsive-btn mt-3">
                      <button className="header_btn" href="/cps">Join Our Club</button>
                    </li>
                  </ul>
                </nav>
              </div>
              <div className="d-none d-xs-inline-flex">
                <a className='header_btn' href="/cps">Join Our Club</a>
              </div>
              <div className="mobile-menu-trigger">
                <span></span>
              </div>
            </div>
          </div>
        </header>
      );
    }
  }

  export default Header;