import React from 'react';
import Frame from "../../components/frame";
import { useParams } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
import moment from 'moment';
import Caesium from "../../contracts/Caesium.json";
import Caesium_Cps from "../../contracts/Caesium_Cps.json";
import Caesium_Member from "../../contracts/Caesium_Member.json";
import Web3 from "web3";
import { createWeb3Modal, defaultConfig, useWeb3Modal, useWeb3ModalAccount, useWeb3ModalSigner } from '@web3modal/ethers5/react';

const projectId = 'fe5331da52e0e0ec1aeddc58e22e3e1e';

const binanceSmartChain = {
  chainId: 56,
  name: 'Binance Smart Chain',
  currency: 'BNB',
  explorerUrl: 'https://bscscan.com',
  rpcUrl: 'https://rpc.ankr.com/bsc'
}

const metadata = {
  name: 'Caesium',
  description: 'Caesium',
  url: 'https://csm.caesiumlab.com/',
  icons: ['https://caesiumlab.com/web/assets/images/token3.png']
}

const modal = createWeb3Modal({
  ethersConfig: defaultConfig({ metadata }),
  chains: [binanceSmartChain],
  projectId,
  themeMode: 'light',
  themeVariables: {
    '--w3m-z-index': 999,
    '--w3m-color-mix': '#fff',
    '--w3m-color-mix-strength': 40
  }
})

const initialState = {
  loading:false,
  is_connected:false,
  web3: null,
  accounts: null,
  contract: null,
  contractMember: null,
  contractAddress: null,
  contractAddress_Short:'-',
  YourAddress:'-----------',
  YourAddressfull:null,
  sponsor_address: '',
  userInfo:{},      
  user_referer:'-----------',
  balance:0,
  balanceEthVal:0,
  amount:'',
  UsdToBnb:0,
  is_referrer:false,
  // CPS
  contract_Cps:null,
  totalDepositInUSD_Cps:0,
  amount_cps: 200,
  _level_Cps:0,
  _bonus_Cps:0,
  userCount_Cps:0,
  userInfo_Cps:{},
  user_referer_Cps:'-----------',
  is_referrer_Cps: false,
  sponsor_address_Cps : '',
  userInfo_Member:{},
  TotalCPS_qualifier : 0
};

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading:false,
      web3: null,
      accounts: null,
      contract: null,
      contractMember: null,
      contractAddress: null,
      contractAddress_Short:'-',
      YourAddress:'-----------',
      YourAddressfull:null,
      sponsor_address: (this.props?.referral != 'undefined') ? this.props?.referral : '',
      userInfo:{},      
      user_referer:'-----------',
      balance:0,
      balanceEthVal:0,
      amount:'',
      UsdToBnb:0,
      is_referrer:false,
      // CPS
      contract_Cps:null,
      totalDepositInUSD_Cps:0,
      amount_cps: 200,
      _level_Cps:0,
      _bonus_Cps:0,
      userCount_Cps:0,
      userInfo_Cps:{},
      userInfo_Member:{},
      user_referer_Cps:'-----------',
      is_referrer_Cps: false,
      sponsor_address_Cps : (this.props?.referral != 'undefined') ? this.props?.referral : '',
      TotalCPS_qualifier : 0
    }
  }
  
  componentDidMount = async () => {
    try {
      let contractAddress_Short = Caesium.address.slice(0, 10) + '.....' + Caesium.address.slice(Caesium.address.length-5, Caesium.address.length);
      this.setState({contractAddress_Short,contractAddress:Caesium.address});
    } catch (error) {
      this.setState({networkMain:false,loading:false});
      console.error(error);
    }    
  };


  componentDidUpdate = async () => {
    if(this.props?.address && this.props?.address!= this.state.accounts){
      this.setState({accounts:this.props?.address});
      this.connectToWallet();
    }

    if(this.state.accounts && !this.props?.isConnected){
      this.disconnectFromWallet();
    }
    
  }


  connectToWallet = async (e) => {
    try {        
      // Get network provider and web3 instance.
      let provider = this.props?.walletProvider;
      let signer = this.props?.signer;
      let web3 = new Web3(modal);        
      web3.setProvider(binanceSmartChain.rpcUrl);
      // Use web3 to get the user's accounts.
      const accounts = this.props?.address;

      const networkId = this.props?.chainId;
      if(networkId !== 56) {
        enqueueSnackbar("Error! Please connect to bsc mainnet",{ variant: 'error' });
        this.setState({loading:false});
        return false;
      }
       
      if(this.state.accounts !== accounts){
        // Get the contract instance.
        
        this.setState({loading:true});
        const deployedNetwork = Caesium.networks;
        const instance = new web3.eth.Contract(
          Caesium.abi,
          deployedNetwork && Caesium.address, provider
        );

        const deployedNetwork_cps = Caesium_Cps.networks;
        const instance_cps = new web3.eth.Contract(
          Caesium_Cps.abi,
          deployedNetwork_cps && Caesium_Cps.address, provider
        );
        const instance_csmMember= new web3.eth.Contract(
          Caesium_Member.abi,
          deployedNetwork_cps && Caesium_Member.address, provider
        );

        let user_account = accounts;
        

        this.setState({loading:false, is_connected:true});
        this.setState({ web3, accounts:user_account,contractMember:instance_csmMember, contract: instance,contract_Cps: instance_cps, contractAddress:Caesium.address,contractAddress_Cps: Caesium_Cps.address,loading:true}, this.fetchData);
        
        //this.setState({ web3, accounts:user_account, contract: instance, contractAddress:deployedNetwork.address}, this.fetchData);
        enqueueSnackbar("Wallet Connected",{ variant: 'success' });
      }else{
        this.setState({loading:false, is_connected:true});
        enqueueSnackbar("Wallet Connected",{ variant: 'success' });
      }
    } catch (error) {
      // Catch any errors for any of the above operations.
      enqueueSnackbar(error.message,{ variant: 'error' });
      this.setState({networkMain:false});
      console.error(error);
      this.setState({loading:false});
    }      
  };

  disconnectFromWallet = async () => {
    try {
      this.setState({loading:true});
      if(this.state.web3){
        enqueueSnackbar("Wallet disconnected",{ variant: 'success' });
      }        
      this.setState(initialState);
    } catch (error) {
      // Catch any errors for any of the above operations.
      enqueueSnackbar(error.message,{ variant: 'error' });
      this.setState({networkMain:false});
      console.error(error);
      this.setState({loading:false});
    } 
  }

  fetchData = async() => {    
    let { accounts, contract,contract_Cps, contractAddress,contractMember } = this.state;
    let contractAddress_Short = contractAddress.slice(0, 10) + '.....' + contractAddress.slice(contractAddress.length-5, contractAddress.length);
    let YourAddress = accounts.slice(0, 10) + '.....' + accounts.slice(accounts.length-5, accounts.length);
    let YourAddressfull = accounts;

    let userInfo = await contract.methods.users(accounts).call();
    let userInfo_Member = await contractMember.methods.users(accounts).call();
    console.log('userInfo_Member',userInfo_Member)
    let user_referer = '---------';
    let sponsor_address = this.state.sponsor_address;
    let is_referrer = false;
    if(userInfo.referrer && !userInfo.referrer.includes('0x000')){
      user_referer = userInfo.referrer;
      user_referer = user_referer.slice(0, 10) + '.....' + user_referer.slice(user_referer.length-5, user_referer.length);
      sponsor_address = userInfo.referrer;
      is_referrer = true;
    }else{      
      sponsor_address = (this.props?.referral !== undefined) ? this.props?.referral : '';
      if(sponsor_address === undefined){
        sponsor_address = '';
      }
    }
    
    let balance = await this.state.web3.eth.getBalance(accounts);
    balance = parseInt(balance.toString());
    let balanceEthVal = this.state.web3.utils.fromWei(balance, 'ether');

    let getLatestPrice = await contract.methods.getLatestPrice().call();
    let UsdToBnb = this.state.UsdToBnb;
    if(getLatestPrice){
      UsdToBnb = getLatestPrice.toString()/1e8;
    }    

    // ==CPS ==

    let userInfo_Cps = await contract_Cps.methods.users(accounts).call();
    let user_referer_Cps = '---------';
    let sponsor_address_Cps= this.state.sponsor_address_Cps;
    let is_referrer_Cps = false;
    if(userInfo_Cps.referrer && !userInfo_Cps.referrer.includes('0x000')){
      user_referer_Cps = userInfo_Cps.referrer;
      user_referer_Cps = user_referer_Cps.slice(0, 10) + '.....' + user_referer_Cps.slice(user_referer_Cps.length-5, user_referer_Cps.length);
      sponsor_address_Cps = userInfo_Cps.referrer;
      is_referrer_Cps = true;
    }else{      
      sponsor_address_Cps = (this.props?.referral !== undefined) ? this.props?.referral : '';
      if(sponsor_address_Cps === undefined){
        sponsor_address_Cps = '';
      }
    }
    let userLevel = await contract_Cps.methods.userLevel(accounts).call();    
    let _level_Cps = userLevel._level.toString();
    let _bonus_Cps = userLevel._bonus.toString()/1e8;
    let userCount_Cps = await contract_Cps.methods.userCount().call();
    userCount_Cps = parseInt(userCount_Cps.toString());
    let TotalCPS_qualifier = await contract_Cps.methods.TotalCPS_qualifier().call(); 
    TotalCPS_qualifier = parseInt(TotalCPS_qualifier.toString());

    let totalDepositInUSD_Cps = 0;
    if(userInfo_Cps.investInUsd){
      totalDepositInUSD_Cps = (userInfo_Cps.investInUsd.toString() / 1e8);
    }
    


    this.setState({
      contractAddress_Short,
      userInfo,
      user_referer:user_referer,
      is_referrer,
      sponsor_address:sponsor_address,
      YourAddress,
      YourAddressfull,
      balance,
      balanceEthVal,
      UsdToBnb,
      userInfo_Cps,
      user_referer_Cps,
      is_referrer_Cps,
      sponsor_address_Cps,
      _level_Cps,
      _bonus_Cps,
      userCount_Cps,
      totalDepositInUSD_Cps,
      TotalCPS_qualifier,
      userInfo_Member,
      loading:false
    });
  }


  doBuy_Cps= async () => {
    let sponsor_address = this.state.sponsor_address_Cps;
    
    if(!sponsor_address){
      enqueueSnackbar("Sponsor Address is required!",{ variant: 'error' })
      this.setState({sponsor_addressError:true});
      return false;
    }

    if(!this.state.web3.utils.isAddress(sponsor_address) || /^0x00+$/.test(sponsor_address)){
      enqueueSnackbar("Sponsor Address is invalid!",{ variant: 'error' })
      this.setState({sponsor_addressError:true});
      return false;
    }

    if (this.state.is_referrer_Cps){
      enqueueSnackbar("You are already the part of caesium lab membership!",{ variant: 'info' })
      this.setState({amountError:true});
      return false;
    }
    if (parseFloat(this.state.totalDepositInUSD_Cps) > 0){
      enqueueSnackbar("You are already the part of caesium lab membership!",{ variant: 'info' })
      this.setState({amountError:true});
      return false;
    }
  
    let balance = await this.state.web3.eth.getBalance(this.state.accounts);
    balance = parseInt(balance.toString());
    let balanceEthVal = this.state.web3.utils.fromWei(balance, 'ether');
    let amount = parseFloat(this.state.amount_cps);
    amount = amount + (amount*5)/100;
    let amountBNB = amount/this.state.UsdToBnb;
    if(sponsor_address){
      if(parseFloat(balanceEthVal) >= parseFloat(amountBNB)){
        try {
          this.setState({loading:true});

          let weiValue = this.state.web3.utils.toWei(amountBNB.toString(), 'ether');
          let gasPrice = await this.state.web3.eth.getGasPrice();
          gasPrice = parseInt(gasPrice)+20;
          let gasLimit = 10000000;
          let estimateGas = 100000;
          let options = {
            from:this.state.accounts,
            gas:gasLimit,
            value:weiValue
          }
          let data1 =await this.state.contract_Cps.methods.invest(sponsor_address).estimateGas(options);
          estimateGas = parseInt(data1)+10000;

          let invest = await this.state.contract_Cps.methods.invest(sponsor_address).send(
            {
              from: this.state.accounts,
              value:weiValue,
              data:{
                "inputs": [
                  {
                    "internalType": "address",
                    "name": "_referrer",
                    "type": "address"
                  }
                ],
                "name": "invest",
                "outputs": [],
                "stateMutability": "payable",
                "type": "function"
              },
              gas:estimateGas.toString(),
              gasPrice:gasPrice.toString()
            }
          );
          if(invest.status){
            this.setState({loading:false});
            enqueueSnackbar("Buy Successfully!",{ variant: 'success' });
            this.fetchData();
          }else{
            this.setState({loading:false});
            enqueueSnackbar('Some Network Error Occurred!');
          }
        }
        catch(err) {
          //console.log(err);
          this.setState({loading:false});
          if (err.message.includes("User denied transaction signature")) {
            // handle the "error" as a rejection
            enqueueSnackbar(err.message,{ variant: 'error' });
          }else{
            enqueueSnackbar(err,{ variant: 'error' });
          }
        }
      }else{
        this.setState({loading:false});
        enqueueSnackbar("Insufficient Balance!",{ variant: 'error' });
      }
    }else{
      this.setState({loading:false});
      enqueueSnackbar("Please enter sponsor address!",{ variant: 'error' });        
    }
  }

  doBuy= async () => {
    let sponsor_address = this.state.sponsor_address_Cps;

    if (!this.state.accounts){
      enqueueSnackbar("Please Connect Your Wallet",{ variant: 'error' })
      this.setState({amountError:true});
      return false;
    }
    if (this.state.userInfo_Member.memberShipStatus){
      enqueueSnackbar("You are already the part of caesium lab membership!",{ variant: 'info' })
      this.setState({amountError:true});
      return false;
    }
    let balance = await this.state.web3.eth.getBalance(this.state.accounts);
    balance = parseInt(balance.toString());
    let balanceEthVal = this.state.web3.utils.fromWei(balance, 'ether');
    let amount = parseFloat(this.state.amount_cps);
    amount = amount + (amount*0.5)/100;
    let amountBNB = amount/this.state.UsdToBnb;
      if(parseFloat(balanceEthVal) >= parseFloat(amountBNB)){
        try {
          this.setState({loading:true});

          let weiValue = this.state.web3.utils.toWei(amountBNB.toString(), 'ether');
          let gasPrice = await this.state.web3.eth.getGasPrice();
          gasPrice = parseInt(gasPrice)+20;
          let gasLimit = 10000000;
          let estimateGas = 100000;
          let options = {
            from:this.state.accounts,
            gas:gasLimit,
            value:weiValue
          }
          let data1 =await this.state.contractMember.methods.invest().estimateGas(options);
          estimateGas = parseInt(data1)+10000;

          let invest = await this.state.contractMember.methods.invest().send(
            {
              from: this.state.accounts,
              value:weiValue,
              data:{
                "inputs": [],
                "name": "invest",
                "outputs": [],
                "stateMutability": "payable",
                "type": "function"
              },
              gas:estimateGas.toString(),
              gasPrice:gasPrice.toString()
            }
          );
          if(invest.status){
            this.setState({loading:false});
            enqueueSnackbar("Buy Successfully!",{ variant: 'success' });
            this.fetchData();
          }else{
            this.setState({loading:false});
            enqueueSnackbar('Some Network Error Occurred!');
          }
        }
        catch(err) {
          //console.log(err);
          this.setState({loading:false});
          if (err.message.includes("User denied transaction signature")) {
            // handle the "error" as a rejection
            enqueueSnackbar(err.message,{ variant: 'error' });
          }else{
            enqueueSnackbar(err,{ variant: 'error' });
          }
        }
      }else{
        this.setState({loading:false});
        enqueueSnackbar("Insufficient Balance!",{ variant: 'error' });
      }
    
  }


  WithdrawBonus_Cps = async () => {
    if(this.state._bonus_Cps === 0){
      enqueueSnackbar("You don't have enough balance!",{ variant: 'error' });
      return false;
    }
    try {
      this.setState({loading:true});

      let weiValue = 0;
      let gasPrice = await this.state.web3.eth.getGasPrice();
      gasPrice = parseInt(gasPrice)+20;
      let gasLimit = 10000000;
      let estimateGas = 100000;
      let options = {
        from:this.state.accounts,
        gas:gasLimit,
        value:weiValue
      }
      let data1 =await this.state.contract_Cps.methods.withdrawBonus().estimateGas(options);
      estimateGas = parseInt(data1)+10000;

      let result = await this.state.contract_Cps.methods.withdrawBonus().send(
        {
          from: this.state.accounts,
          value:weiValue,
          data:{
            "inputs": [],
            "name": "withdrawBonus",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
          },
          gas:estimateGas.toString(),
          gasPrice:gasPrice.toString()
        }
      );
      if(result.status){              
        this.setState({amountBNB:null,loading:false});
        this.fetchData();
        enqueueSnackbar("Withdrwan Successfully!",{ variant: 'success' });        
      }else{
        this.setState({loading:false});
        enqueueSnackbar('Some Network Error Occurred!');
      }
    }
    catch(err) {
      this.setState({loading:false});
      if (err.message.includes("User denied transaction signature")) {
        // handle the "error" as a rejection
        enqueueSnackbar(err.message,{ variant: 'error' });
      }else{
        enqueueSnackbar(err,{ variant: 'error' });
      }          
    }
  }


  render() {
    //const { t } = this.props;
    let this2 = this;

    return (
      <Frame withHeader={false} withFooter={true}>
        <div className="">
          {this.state.loading ? (
            <div className="metaportal_fn_preloader">
              <div className="loading-container">
                <div className="loading">
                  <div className="l1">
                    <div></div>
                  </div>
                  <div className="l2">
                    <div></div>
                  </div>
                  <div className="l3">
                    <div></div>
                  </div>
                  <div className="l4">
                    <div></div>
                  </div>
                </div>
              </div>
            </div>
          ) : (null)}

          <header className="site-header">
            <div className="navbar">
              <div className="container">
                <div className="brand-logo">
                  <a href="/">
                    <img className="dark_logo" src="assets/img/dark-logo.png" alt="" />
                  </a>
                </div>
                <div className="menu-block-wrapper">
                  <div className="menu-overlay"></div>
                  <nav className="menu-block" id="append-menu-header">
                    <div className="mobile-menu-head">
                      <div className="go-back">
                        <i className="fa fa-angle-left"></i>
                      </div>
                      <div className="current-menu-title">
                        <img className="mobile_menu_logo" src="assets/img/dark-logo.png" alt="" />
                      </div>
                      <div className="mobile-menu-close">&times;</div>
                    </div>
                    <ul className="site-menu-main">
                      <li className="fugu-responsive-btn mt-3">
                        {this.props.isConnected ? (
                          <button disabled={this.state.loading} onClick={()=>this.props.open()} className='header_btn'>Wallet Disconnect</button>
                        ):(
                          <button disabled={this.state.loading} onClick={()=>this.props.open()} className='header_btn'>Wallet Connect</button>
                        )}
                      </li>
                    </ul>
                  </nav>
                </div>
                <div className="d-none d-xs-inline-flex">
                  {this.props.isConnected ? (
                    <a className='header_btn' disabled={this.state.loading} onClick={()=>this.props.open()}>
                    Wallet Disconnect
                    </a>
                  ):(
                    <a className='header_btn' disabled={this.state.loading} onClick={()=>this.props.open()}>
                    Wallet Connect
                    </a>
                  )}
                </div>
                <div className="mobile-menu-trigger">
                  <span></span>
                </div>
              </div>
            </div>
          </header>

          <div className="header_padding"></div>


          <section className="pt-5 pb-0">
            <div className="container">
              <div className="Profit_Sharing_box cps_join_bg" >
               

                {this.state.userInfo_Member.memberShipStatus?(
                  <div className='text-center'>
                     <h2 className='mb-3 text-success'>Congratulations</h2>
                  <h5 className='mb-0'>You have successfully acquire the<strong> Caesium Membership.</strong></h5>
                  <img className='purchase_success mb-0' src="assets/img/purchase_success.png" alt="" />
                 
                </div> 
                ):(
                  <div className='text-center'>
                  <h2 className='mb-4'>Join the Caesium Community</h2>
                  <p>Unlock exclusive benefits and perks by subscribing to our packages. Click <br /> "Buy" NOW to get started on your journey with us.</p>
                  <div className='mt-4'>
                  <h6  className="mb-4"><strong>MEMBERSHIP SUBSCRIPTION AMOUNT IS ${this.state.amount_cps} ({parseFloat(this.state.UsdToBnb>0?this.state.amount_cps/this.state.UsdToBnb:0).toFixed(5)} BNB)</strong></h6>
                    <button class="header_btn" onClick={()=>this.doBuy()}>Buy Now</button>
                  </div>
                </div>
                )}
                
                 
              </div>
            </div>
          </section>
          
          <section className="pt-5 pb-5">
            <div className="container">
              <div className="Cycle-based_bg p-4">
                <div className="cps_heading_text heading_middle mb-5">
                  <h2 className="mb-3">Caesium Profit Sharing - CPS</h2>
                  <h5 className="mb-0">BEST PART OF BEING A CAESIUM MEMBER</h5>
                </div>
              
                <div className="row g-4">
                  <div className="col-md-8 col-lg-8">
                    <ul className="cps_no_ul mb-4">
                      <li>
                        <span className="no">1</span>
                        <h5>Auto Placement Platform</h5>
                        <p>Introduce 3 ID in cycle 1 to qualify for Auto Placement</p>
                      </li>
                      <li>
                        <span className="no">2</span>
                        <h5>Max Payout upto USD <span className="font-weight">1.1 Million</span></h5>
                        <p>All Payments in USD</p>
                      </li>
                      <li>
                        <span className="no">3</span>
                        <h5>Profit Sharing from Global Profit and Projects</h5>
                        <p>Majority of Global Profits shared from cycle 2 to 12</p>
                      </li>
                    </ul>
                    <h4 className="mb-3">CPS Growth</h4>
                    <div className="cps_input_area">
                      <div className="row mb-3">
                        <div className="col-md-6 col-lg-6">
                          <div className="mb-4">
                            <label>TOTAL MEMBERS</label>
                            <input className="cps_input" readOnly type="text" value={this.state.userCount_Cps} />
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-6">
                          <div className="mb-4">
                            <label>CPS QUALIFIER</label>
                            <input className="cps_input" readOnly type="text" value={this.state.TotalCPS_qualifier} />
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-6">
                          <div className="mb-4">
                            <label>CURRENT LEVEL</label>
                            <input className="cps_input" readOnly type="text" value={this.state._level_Cps} />
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-6">
                          <div className="mb-4 position-relative">
                            <div className="d-flex align-items-center justify-content-between">
                              <label>EARNED PROFIT</label>
                              {this.state._bonus_Cps > 0 ? (
                                <button disabled={true} 
                                // onClick={this.WithdrawBonus_Cps} 
                                className="w_Profit_btn mb-2">Withdraw Profit</button>
                              ):(
                                <button disabled readOnly className="w_Profit_btn mb-2">Withdraw Profit</button>
                              )}                              
                            </div>
                            <input className="cps_input" readOnly type="text" value={`$${this.state._bonus_Cps}`} />
                          </div>
                        </div>
                      </div>
                      <h6 className="mb-4">MEMBERSHIP SUBSCRIPTION AMOUNT IS ${this.state.amount_cps} ({parseFloat(this.state.amount_cps/this.state.UsdToBnb).toFixed(5)} BNB)</h6>
                      <div className="mb-4">
                        <label>Sponsor wallet address</label>
                        <div className="position-relative">                          
                          {this.state.is_referrer ? (
                            <input type="text" placeholder='Referral Address'
                              className="cps_input"
                              disabled readOnly
                              value={this.state.user_referer || ''}                          
                            />
                          ) : (
                            <input type="text" placeholder='Referral Address'
                              className="cps_input"
                              value={this.state.sponsor_address_Cps || ''}
                              onChange={(e)=>this.setState({sponsor_address_Cps:e.target.value})} 
                            />
                          )}
                          <button
                          //  onClick={this.doBuy_Cps} 
                          disabled={true} className="header_btn join_btn_position" type="submit">Join Now</button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-lg-4">
                    <div className="Cycle-based_table cps_table">
                      <table className="table table-border table-striped">
                        <thead>
                          <tr>
                            <td>Cycle</td>
                            <td>Profit Sharing (USD)</td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className={this.state._level_Cps === 1? 'current_level':null} data-label="CYCLE">1</td>
                            <td className={this.state._level_Cps === 1? 'current_level':null} data-label="PROFIT SHARING(USD)">$100</td>
                          </tr>
                          <tr>
                            <td className={this.state._level_Cps === 2? 'current_level':null} data-label="CYCLE">2</td>
                            <td className={this.state._level_Cps === 2? 'current_level':null} data-label="PROFIT SHARING(USD)">$120</td>
                          </tr>
                          <tr>
                            <td className={this.state._level_Cps === 3? 'current_level':null} data-label="CYCLE">3</td>
                            <td className={this.state._level_Cps === 3? 'current_level':null} data-label="PROFIT SHARING(USD)">$200</td>
                          </tr>
                          <tr>
                            <td className={this.state._level_Cps === 4? 'current_level':null} data-label="CYCLE">4</td>
                            <td className={this.state._level_Cps === 4? 'current_level':null} data-label="PROFIT SHARING(USD)">$350</td>
                          </tr>
                          <tr>
                            <td className={this.state._level_Cps === 5? 'current_level':null} data-label="CYCLE">5</td>
                            <td className={this.state._level_Cps === 5? 'current_level':null} data-label="PROFIT SHARING(USD)">$610</td>
                          </tr>
                          <tr>
                            <td className={this.state._level_Cps === 6? 'current_level':null} data-label="CYCLE">6</td>
                            <td className={this.state._level_Cps === 6? 'current_level':null} data-label="PROFIT SHARING(USD)">$1,140</td>
                          </tr>
                          <tr>
                            <td className={this.state._level_Cps === 7? 'current_level':null} data-label="CYCLE">7</td>
                            <td className={this.state._level_Cps === 7? 'current_level':null} data-label="PROFIT SHARING(USD)">$2,280</td>
                          </tr>
                          <tr>
                            <td className={this.state._level_Cps === 8? 'current_level':null} data-label="CYCLE">8</td>
                            <td className={this.state._level_Cps === 8? 'current_level':null} data-label="PROFIT SHARING(USD)">$6,840</td>
                          </tr>
                          <tr>
                            <td className={this.state._level_Cps === 9? 'current_level':null} data-label="CYCLE">9</td>
                            <td className={this.state._level_Cps === 9? 'current_level':null} data-label="PROFIT SHARING(USD)">$19,000</td>
                          </tr>
                          <tr>
                            <td className={this.state._level_Cps === 10? 'current_level':null}  data-label="CYCLE">10</td>
                            <td className={this.state._level_Cps === 10? 'current_level':null} data-label="PROFIT SHARING(USD)">$85,600</td>
                          </tr>
                          <tr>
                            <td className={this.state._level_Cps === 11? 'current_level':null} data-label="CYCLE">11</td>
                            <td className={this.state._level_Cps === 11? 'current_level':null} data-label="PROFIT SHARING(USD)">$371,800</td>
                          </tr>
                          <tr>
                            <td className={this.state._level_Cps === 12? 'current_level':null} data-label="CYCLE">12</td>
                            <td className={this.state._level_Cps === 12? 'current_level':null} data-label="PROFIT SHARING(USD)">$612,000</td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr>
                            <td data-label="CYCLE">Total</td>
                            <td data-label="PROFIT SHARING(USD)">$1,100,000</td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </div>
                </div>
                <div className='Cycle_based_bg_overlay'></div>
                <div className='cps_lock'>
                  <div class="wave__container">
                    <img src="assets/img/lock.png" alt="" />
                    <div class="wave__circle"></div>
                    <div class="wave__circle"></div>
                    <div class="wave__circle"></div>
                    <div class="wave__circle"></div>
                    <div class="wave__circle"></div>
                  </div>
                </div>
              </div>
             
            </div>
          </section>
          
          <section class="pt-2 pb-4">
            <div className="container">
              <div className="youtube-container">
                <iframe src="https://www.youtube.com/embed/qpJzaXtOrfQ" title="Caesium Profit Sharing Club (CPS)" allowfullscreen></iframe>
              </div>
            </div>
          </section>

       


          {/* <section className="cta-area pt-3">
            <div className="container">
           
              <div className="row">
                <div className="col-12">
                  <div className="card">
                      <div className="row justify-content-center">
                        <div className="col-12 col-md-8 col-lg-7">
                            <div className="intro text-center">
                                <div className="intro-content">
                                <h2 className="title_Cps">Caesium Profit Sharing - CPS</h2>
                                <span className="intro-text">Best part of being a Caesium Member</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center justify-content-center">
                      <div className="col-12 col-md-8 mt-4 mt-md-0">
                        <ul className="Profit_haring_list">
                          <li>
                            <h4>Auto Placement Platform</h4>
                            <p>Introduce 3 ID in cycle 1 to qualify for Auto Placement</p>
                          </li>
                          <li>
                            <h4>Max Payout upto USD <strong>1.1 Million</strong></h4>
                            <p>All Payments in USD</p>
                          </li>
                          <li>
                            <h4>Profit Sharing from Global Profit and Projects</h4>
                            <p>Majority of Global Profits shared from cycle 2 to 12</p>
                          </li>
                        </ul>
                        <div className="mb-2">
                          <div className='intro-content mb-3'>
                            <h2 className="title_Cps"><strong>CPS Growth</strong></h2>
                          </div>
                          <div className="row justify-content-center">
                            <div className="col-md-6 col-sm-6 col-lg-6 col-12">
                              <div className="investment_box">
                                <div className="investment_inner">
                                  <h5>Total Members</h5>
                                  <h4>{this.state.userCount_Cps}</h4>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6 col-sm-6 col-lg-6 col-12">
                              <div className="investment_box">
                                <div className="investment_inner">
                                  <h5> CPS Qualifier</h5>
                                  <h4>{this.state.TotalCPS_qualifier}</h4>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6 col-sm-6 col-lg-6 col-12">
                              <div className="investment_box">
                                <div className="investment_inner">
                                  <h5>Current Level</h5>
                                  <h4>{this.state._level_Cps}</h4>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6 col-sm-6 col-lg-6 col-12">
                              <div className="investment_box">
                                <div className="investment_inner">
                                  <h5>
                                  Earned Profit
                                  </h5>
                                  <h4>${this.state._bonus_Cps} 
                                  </h4>
                                  {this.state._bonus_Cps > 0 ? (
                                    <button onClick={this.WithdrawBonus_Cps} className="Withdraw_btn">Withdraw Profit <i className="icon-withdraw ml-1"></i></button>
                                  ):(
                                    <button disabled readOnly className="Withdraw_btn">Withdraw Profit <i className="icon-withdraw ml-1"></i></button>
                                  )}
                                  
                                </div>
                              </div>
                            </div>
                            
                            
                          </div>
                        </div>
                        <div className="intro mb-3">
                          <span className="intro-text line_remove">
                            Membership Subscription Amount is ${this.state.amount_cps} ({parseFloat(this.state.amount_cps/this.state.UsdToBnb).toFixed(5)} BNB)
                          </span>
                        </div>
                          <div className="row">
                            <div className="col-12 col-md-8 col-lg-8">
                              <div className="form-group">
                              <label className="mb-2">Sponsor wallet address</label>
                              {this.state.is_referrer ? (
                                <input type="text" placeholder='Referral Address'
                                  disabled readOnly
                                  value={this.state.user_referer || ''}                          
                                />
                              ) : (
                                <input type="text" placeholder='Referral Address'
                                  value={this.state.sponsor_address_Cps || ''}
                                  onChange={(e)=>this.setState({sponsor_address_Cps:e.target.value})} 
                                />
                              )}
                            </div>
                            </div>
                            <div className="col-12 col-md-4 col-lg-4">
                            <label className="mb-2">&nbsp;</label>
                              <button onClick={this.doBuy_Cps} disabled={this.state.loading} className="btn btn-block btn-bordered active">Join Now <i className="icon-login ml-2"></i></button>
                            </div>
                          </div>
                      </div>
                      <div className="col-12 col-md-4 text-center Cps_Main">
                        <table className="mt-4 table_Cps transection__table dashboard__table">
                          <thead>
                            <tr>
                              <th>CYCLE</th>
                              <th>PROFIT SHARING(USD)</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                                <td className={this.state._level_Cps === 1? 'current_level':null} data-label="CYCLE">1</td>
                                <td className={this.state._level_Cps === 1? 'current_level':null} data-label="PROFIT SHARING(USD)">$100</td>
                            </tr>
                            <tr>
                                <td className={this.state._level_Cps === 2? 'current_level':null} data-label="CYCLE">2</td>
                                <td className={this.state._level_Cps === 2? 'current_level':null} data-label="PROFIT SHARING(USD)">$120</td>
                            </tr>
                            <tr>
                                <td className={this.state._level_Cps === 3? 'current_level':null} data-label="CYCLE">3</td>
                                <td className={this.state._level_Cps === 3? 'current_level':null} data-label="PROFIT SHARING(USD)">$200</td>
                            </tr>
                            <tr>
                                <td className={this.state._level_Cps === 4? 'current_level':null} data-label="CYCLE">4</td>
                                <td className={this.state._level_Cps === 4? 'current_level':null} data-label="PROFIT SHARING(USD)">$350</td>
                            </tr>
                            <tr>
                                <td className={this.state._level_Cps === 5? 'current_level':null} data-label="CYCLE">5</td>
                                <td className={this.state._level_Cps === 5? 'current_level':null} data-label="PROFIT SHARING(USD)">$610</td>
                            </tr>
                            <tr>
                                <td className={this.state._level_Cps === 6? 'current_level':null} data-label="CYCLE">6</td>
                                <td className={this.state._level_Cps === 6? 'current_level':null} data-label="PROFIT SHARING(USD)">$1,140</td>
                            </tr>
                            <tr>
                                <td className={this.state._level_Cps === 7? 'current_level':null} data-label="CYCLE">7</td>
                                <td className={this.state._level_Cps === 7? 'current_level':null} data-label="PROFIT SHARING(USD)">$2,280</td>
                            </tr>
                            <tr>
                                <td className={this.state._level_Cps === 8? 'current_level':null} data-label="CYCLE">8</td>
                                <td className={this.state._level_Cps === 8? 'current_level':null} data-label="PROFIT SHARING(USD)">$6,840</td>
                            </tr>
                            <tr>
                                <td className={this.state._level_Cps === 9? 'current_level':null} data-label="CYCLE">9</td>
                                <td className={this.state._level_Cps === 9? 'current_level':null} data-label="PROFIT SHARING(USD)">$19,000</td>
                            </tr>
                            <tr>
                                <td className={this.state._level_Cps === 10? 'current_level':null}  data-label="CYCLE">10</td>
                                <td className={this.state._level_Cps === 10? 'current_level':null} data-label="PROFIT SHARING(USD)">$85,600</td>
                            </tr>
                            <tr>
                                <td className={this.state._level_Cps === 11? 'current_level':null} data-label="CYCLE">11</td>
                                <td className={this.state._level_Cps === 11? 'current_level':null} data-label="PROFIT SHARING(USD)">$371,800</td>
                            </tr>
                            <tr>
                                <td className={this.state._level_Cps === 12? 'current_level':null} data-label="CYCLE">12</td>
                                <td className={this.state._level_Cps === 12? 'current_level':null} data-label="PROFIT SHARING(USD)">$612,000</td>
                            </tr>
                            <tr>
                                <td data-label="CYCLE">Total</td>
                                <td data-label="PROFIT SHARING(USD)">$1,100,000</td>
                            </tr>                       
                            
                          </tbody>
                        </table>
                      </div>
                     
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section> */}

        </div>        
      </Frame>
    );
  }
}

function HomeHOC(props) {
  let { referral } = useParams();
  const { open, close } = useWeb3Modal();
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { signer, walletProvider } = useWeb3ModalSigner();
  
  return <Home 
    referral={referral}
    open={open}
    address={address}
    chainId={chainId}
    isConnected={isConnected}
    signer={signer}
    walletProvider={walletProvider}
    {...props}
  />;
}

export default HomeHOC;