import React from "react";
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";

import Home from "./pages/home/home";
import Cps from "./pages/home/cps";

import './App.css';
//import './i18n';

class App extends React.Component {

  

  render() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route exact path="/cps/:referral?" element={<Cps />}></Route>
          <Route exact path="/" element={<Home />}></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
  }
}

export default App;
